import { default as CoeMhaPpVuy3rMeta } from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/Coe.vue?macro=true";
import { default as EnvironmentalSound8m7jrCzAAOMeta } from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/EnvironmentalSound.vue?macro=true";
import { default as PointsInQXiALpOSMeta } from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/Points.vue?macro=true";
import { default as RecordTestgkByrslyDdMeta } from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/RecordTest.vue?macro=true";
import { default as TextzEaE40DMF5Meta } from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/Text.vue?macro=true";
import { default as FaqZ5qTV6f0NCMeta } from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Faq.vue?macro=true";
import { default as SigninW5YnUEVvtnMeta } from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Signin.vue?macro=true";
import { default as indexvjwoi53c6oMeta } from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/index.vue?macro=true";
export default [
  {
    name: CoeMhaPpVuy3rMeta?.name ?? "Create-Coe",
    path: CoeMhaPpVuy3rMeta?.path ?? "/Create/Coe",
    meta: CoeMhaPpVuy3rMeta || {},
    alias: CoeMhaPpVuy3rMeta?.alias || [],
    redirect: CoeMhaPpVuy3rMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/Coe.vue").then(m => m.default || m)
  },
  {
    name: EnvironmentalSound8m7jrCzAAOMeta?.name ?? "Create-EnvironmentalSound",
    path: EnvironmentalSound8m7jrCzAAOMeta?.path ?? "/Create/EnvironmentalSound",
    meta: EnvironmentalSound8m7jrCzAAOMeta || {},
    alias: EnvironmentalSound8m7jrCzAAOMeta?.alias || [],
    redirect: EnvironmentalSound8m7jrCzAAOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/EnvironmentalSound.vue").then(m => m.default || m)
  },
  {
    name: PointsInQXiALpOSMeta?.name ?? "Create-Points",
    path: PointsInQXiALpOSMeta?.path ?? "/Create/Points",
    meta: PointsInQXiALpOSMeta || {},
    alias: PointsInQXiALpOSMeta?.alias || [],
    redirect: PointsInQXiALpOSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/Points.vue").then(m => m.default || m)
  },
  {
    name: RecordTestgkByrslyDdMeta?.name ?? "Create-RecordTest",
    path: RecordTestgkByrslyDdMeta?.path ?? "/Create/RecordTest",
    meta: RecordTestgkByrslyDdMeta || {},
    alias: RecordTestgkByrslyDdMeta?.alias || [],
    redirect: RecordTestgkByrslyDdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/RecordTest.vue").then(m => m.default || m)
  },
  {
    name: TextzEaE40DMF5Meta?.name ?? "Create-Text",
    path: TextzEaE40DMF5Meta?.path ?? "/Create/Text",
    meta: TextzEaE40DMF5Meta || {},
    alias: TextzEaE40DMF5Meta?.alias || [],
    redirect: TextzEaE40DMF5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Create/Text.vue").then(m => m.default || m)
  },
  {
    name: FaqZ5qTV6f0NCMeta?.name ?? "Faq",
    path: FaqZ5qTV6f0NCMeta?.path ?? "/Faq",
    meta: FaqZ5qTV6f0NCMeta || {},
    alias: FaqZ5qTV6f0NCMeta?.alias || [],
    redirect: FaqZ5qTV6f0NCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Faq.vue").then(m => m.default || m)
  },
  {
    name: SigninW5YnUEVvtnMeta?.name ?? "Signin",
    path: SigninW5YnUEVvtnMeta?.path ?? "/Signin",
    meta: SigninW5YnUEVvtnMeta || {},
    alias: SigninW5YnUEVvtnMeta?.alias || [],
    redirect: SigninW5YnUEVvtnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/Signin.vue").then(m => m.default || m)
  },
  {
    name: indexvjwoi53c6oMeta?.name ?? "index",
    path: indexvjwoi53c6oMeta?.path ?? "/",
    meta: indexvjwoi53c6oMeta || {},
    alias: indexvjwoi53c6oMeta?.alias || [],
    redirect: indexvjwoi53c6oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/pages/index.vue").then(m => m.default || m)
  }
]