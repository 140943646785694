import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import amplify_cCHdTinyKG from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/plugins/amplify.ts";
import bootstrap_icons_RTr7r3zW0c from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/plugins/bootstrap-icons.ts";
import bootstrap_client_Uyd73t1ylZ from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/plugins/bootstrap.client.ts";
import dialog_uimL6oljqr from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/plugins/dialog.ts";
import spinner_7GZSdzheBh from "/codebuild/output/src1638543912/src/toppan-voice-recorder-frontend/plugins/spinner.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  amplify_cCHdTinyKG,
  bootstrap_icons_RTr7r3zW0c,
  bootstrap_client_Uyd73t1ylZ,
  dialog_uimL6oljqr,
  spinner_7GZSdzheBh
]